<template>
  <section v-if="hasNomenclatureList" class="gifts-form">
    <UcForm :handler="saveItem">
      <h4 class="gifts-form_title">{{ giftItem.name }}</h4>
      <div class="gifts-form_ico">
        <img src="https://static.chicco.com.ua/assets/svg/gifts/present.svg" alt="gift" />
      </div>

      <UcGiftItem
        v-for="item in visibleItems"
        :key="item.nomenclatureId"
        :item="item"
        :is-editing="isEditing"
        :is-selectable="giftItem.isSelectable"
        :picked-item-id="pickedItem?.nomenclatureId"
        @click.native="selectItem(item)"
        @edit="setEditing"
      />

      <uc-input
        v-if="isEditing"
        :value="pickedItem"
        rules="required"
        input-classes="hidden-field"
        name="gift-item"
        no-label
      >
        <template #errors="{ errors }">
          <span v-if="errors.length" class="uc-error m-t-4">
            {{ $t('Выберите подарок') }}
          </span>
        </template>
      </uc-input>

      <div
        v-if="!giftInBasket && giftItem.isSelectable && !isEditing"
        class="gifts-form-item__gift"
        @click="setEditing(true)"
      >
        <div class="gifts-form-item__gift-img">
          <img src="https://static.chicco.com.ua/assets/svg/gifts/gift-for-modal.svg" alt="gift" />
        </div>
        <div class="gifts-form-item__gift-title">{{ $t('Подарок') }}</div>
        <div class="gifts-form-item__gift-change">
          <img src="https://static.chicco.com.ua/assets/svg/gifts/pick-ico.svg" alt="pick" />{{ $t('Выбрать подарок') }}
        </div>
      </div>

      <div v-if="isEditing" class="gifts-form_buttons">
        <uc-button class="uc-button-white-blue" @click="setEditing(false)">{{ $t('Отменить') }}</uc-button>
        <uc-button class="uc-button-blue" type="submit">{{ $t('Сохранить') }}</uc-button>
      </div>
    </UcForm>
  </section>
</template>

<script>
import UcGiftForm from 'u-components/components/basket/UcGiftForm.vue';

export default {
  extends: UcGiftForm
};
</script>
