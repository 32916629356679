<template>
  <div class="basket-modal-bonus">
    <loader v-if="loading" />
    <div v-if="bonuses && !loading" class="basket-modal-bonus__box">
      <div class="basket-modal-bonus__box-head">{{ $t('Кешбэк к начислению') }}</div>
      <div class="basket-modal-bonus__box-body">
        <div class="basket-modal-bonus__box-body__coin">
          <div class="coin-count">
            <span>{{ bonuses | currency }}</span>
            {{ $t('кешбэка') }}
          </div>
        </div>
        <uc-link
          class="basket-modal-bonus__box-body__link"
          :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })"
          >{{ $t('Узнай больше о программе МА Кешбэк ') }}
        </uc-link>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '~/src/components/page-blocks/Loader';
import { BonusesForPriceMixin } from 'u-mixins';

export default {
  name: 'BasketBonus',
  components: {
    Loader
  },
  mixins: [BonusesForPriceMixin],
  props: {
    totalPrice: {
      type: Object,
      required: true
    }
  },
  computed: {
    loading() {
      return this.$store.state.bonus.loading;
    },
    bonuses() {
      const basketModel = this.$basket.getBasket();
      return this.getBonusAmount(basketModel.availableBasketItems);
    }
  },
  created() {
    this.$bonus.getBonusProfileIfNotExist();
  }
};
</script>
