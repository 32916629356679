<template>
  <section v-if="infoContent" class="terms-condition">
    <uc-modal v-model="modalDisplay" :toggle-scroll="!nested" class="service-info">
      <div class="popup-window-head">
        <h2 class="popup-window-head__title">
          {{ $t('Пользовательское соглашение') }}
        </h2>
      </div>
      <div class="popup-window-content">
        <uc-render-html class="popup-window-content__text" :html="infoContent.content" />
      </div>
    </uc-modal>

    <p class="oferta-text">
      {{ $t('Подтверждая заказ, я принимаю условия') }}
      <a href="#reception" @click="showModal">{{ $t('пользовательского соглашения') }}</a>
    </p>
  </section>
</template>

<script>
export default {
  props: {
    nested: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infoContent: null,
      modalDisplay: false
    };
  },
  created() {
    this.$store
      .dispatch('getPageBySlug', { slug: 'terms-of-use', lang: this.$i18n.locale })
      .then(({ data }) => {
        this.infoContent = data;
      })
      .catch(() => {
        this.infoContent = this.$options.data().infoContent;
      });
  },
  methods: {
    showModal() {
      this.modalDisplay = true;
    },
    hideModal() {
      this.modalDisplay = false;
    }
  }
};
</script>

<style lang="scss">
.terms-condition {
  letter-spacing: normal;
  .oferta-text {
    @include text(0.75em, $font-regular, $gray);
    margin-bottom: 20px;
  }

  a {
    color: $tundora;
    text-decoration: underline;
    line-height: 18px;
  }

  .service-info {
    .uc-modal {
      @media (min-width: $screen-l) {
        width: 880px;
        max-height: 100%;
      }

      @media (min-width: $screen-sm) and (max-width: $screen-l - 1) {
        width: 680px;
      }

      @media (max-width: $screen-sm - 1) {
        max-height: 100%;
      }

      .uc-modal-content {
        padding: 20px 20px 45px 25px;
        flex-direction: column;
        align-items: initial;
        box-sizing: border-box;

        @media (max-width: $screen-sm - 1) {
          width: 100%;
          padding: 15px 20px 45px 25px;
        }

        .popup-window-content {
          overflow-y: scroll;
          padding-right: 15px;
          height: 70vh;
        }
      }
    }
  }
}
</style>
