<template>
  <div class="basket-modal-list__item">
    <uc-link :to="localePath({ name: 'product-detail-page', params: { id: basketItem.slug } })" class="product-img">
      <uc-image
        :img="basketItem.currentImage"
        :width="'200'"
        :height="'200'"
        alt="product image"
        title="product image"
      />
    </uc-link>
    <div class="product-body-row">
      <div class="product-head">
        <uc-link
          :to="localePath({ name: 'product-detail-page', params: { id: basketItem.slug } })"
          class="product-title"
          ><uc-render-html root-element="span" :html="basketItem.title" />
        </uc-link>
      </div>
      <div v-if="basketItem.colorPresentationValue && basketItem.colorsCount > 1" class="product-perk">
        {{ $t('Цвет') }}:<span>{{ getTranslationBySlug(basketItem.colorPresentationValue) }}</span>
      </div>
      <div v-if="basketItem.sizePresentationValue" class="product-perk">
        {{ $t('Размер') }}:<span>{{ basketItem.sizePresentationValue }}</span>
      </div>
      <div class="product-perk">
        {{ $t('Артикул') }}:<span>{{ basketItem.displayArticle }}</span>
      </div>
      <div class="product-row-count">
        <span
          class="product-row-count__btn"
          :class="{ possible: basketItem.count > 1 }"
          @click="basketItem.count > 1 && $basket.removeFromBasket(basketItem)"
          >-</span
        >
        <span class="product-row-count__number">{{ basketItem.count }}</span>
        <span
          class="product-row-count__btn"
          :class="{ possible: $basket.getTotalBasketCountForItem(basketItem.id) < basketItem.totalAmount }"
          @click="
            $basket.getTotalBasketCountForItem(basketItem.id) < basketItem.totalAmount &&
              $basket.addToBasket(basketItem)
          "
          >+</span
        >
      </div>
    </div>
    <div class="product-body-row check">
      <div class="product-check">
        <div v-if="basketItem.isAvailableForOrder" class="product-check-cost">
          <span v-if="basketItem.totalRecommendedPrice > basketItem.totalFinalPrices" class="recommended">
            {{ basketItem.totalRecommendedPrice | currency }}
          </span>
          {{ basketItem.totalFinalPrices | currency }}
        </div>
        <span v-else class="product-check-not-available">{{ $t('Распродано') }}</span>
        <span v-if="isActiveBonusProgram && bonuses" class="product-check-bonus"
          >+{{ bonuses | currency }} {{ $t('кешбэка') }}</span
        >
      </div>
      <div class="product-check-option" @click="displayModal()">
        <img src="https://static.chicco.com.ua/chicco-assets/svg/option.svg" alt="options" />
      </div>
    </div>
    <uc-modal v-model="dialogDisplay" :close-button="false" :toggle-scroll="false" :loading="loading">
      <div class="options">
        <div class="options__head">{{ $t('Удалить товар из корзины?') }}</div>
        <uc-render-html class="options__title" root-element="span" :html="basketItem.title" />
        <div class="options__actions">
          <uc-button v-if="!isInWishlist" class="uc-button-blue" @click="addToWishListAndDelete()">{{
            $t('Переместить в вишлист')
          }}</uc-button>
          <uc-button class="options_action" @click="$basket.deleteFromBasket(basketItem)">{{
            $t('Удалить')
          }}</uc-button>
          <uc-button :class="isInWishlist ? 'uc-button-blue' : 'options_action'" @click="closeModal()">{{
            $t('Отменить')
          }}</uc-button>
        </div>
      </div>
    </uc-modal>
  </div>
</template>

<script>
import { TranslatorMixin, BonusesForPriceMixin } from 'u-mixins';

import catalogWidgetMixin from 'u-wishlist/lib/mixins/widgets/catalogWidget.mixin.js';

export default {
  mixins: [catalogWidgetMixin, TranslatorMixin, BonusesForPriceMixin],
  props: {
    basketItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogDisplay: false,
      loading: false,
      isActiveBonusProgram: process.env.BONUS_PROGRAM
    };
  },
  computed: {
    isInWishlist() {
      return this.$wishList.isItemInWishLists(this.basketItem.productId, this.basketItem.id);
    },
    bonuses() {
      return this.getBonusAmount([this.basketItem]);
    }
  },
  mounted() {
    this.$eventBus.$on('add-to-wish-list-modal:item-added', this.onAddedToWishList);
  },
  beforeDestroy() {
    this.$eventBus.$off('add-to-wish-list-modal:item-added', this.onAddedToWishList);
  },
  methods: {
    displayModal() {
      this.dialogDisplay = true;
    },
    closeModal() {
      this.dialogDisplay = false;
    },
    addToWishListAndDelete() {
      if (this.loading) {
        return null;
      }

      if (!this.$store.state.user.auth) {
        this.$eventBus.$emit('basket_modal:close');
        this.$eventBus.$emit('modals:register:open');
      } else {
        this.loading = true;
        this.$api.SearchService.getProduct(this.basketItem.slug)
          .then(({ data: product }) => {
            this.$eventBus.$emit('add-to-wish-list-modal:display', {
              product: product,
              nomenclatureId: this.basketItem.id,
              basketItemKey: this.basketItem.key,
              title: 'Добавить в cписок желаний?',
              isSizeHidden: true
            });
          })
          .catch((error) => {
            console.error('Add To Wish List And Delete Error:', error);
          })
          .finally(() => {
            this.loading = false;
            this.closeModal();
          });
      }
    },
    onAddedToWishList(item) {
      if (this.basketItem.key === item.basketItemKey) {
        this.$basket.deleteFromBasket(this.basketItem);
      }
    }
  }
};
</script>
