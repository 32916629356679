<template>
  <uc-modal v-model="show" :toggle-scroll="false">
    <div class="basket-notification">
      <div v-if="showNotAvailable" class="basket-notification_content">
        <div class="basket-notification_icon">
          <div class="icon-basket"></div>
        </div>
        <div v-if="showNotAvailableTitle" class="basket-notification_content">
          <div class="basket-notification_title">
            {{ $t('К сожалению, товар') }} "{{ title }}" {{ $t('из Вашей корзины распродан') }}
          </div>
          <uc-link
            :to="localePath({ name: 'product-catalog-page', params: productCategorySlug })"
            class="basket-notification_link"
          >
            <uc-button class="uc-button-blue" @click="hideModal()">{{ $t('Смотреть похожие') }}</uc-button>
          </uc-link>
        </div>
        <div v-else class="basket-notification_content">
          <div class="basket-notification_title">
            {{ $t('К сожалению, некоторые товары из вашей корзины распроданы') }}
          </div>
          <uc-button class="uc-button-blue" @click="toBasket()">{{ $t('Перейти в корзину') }}</uc-button>
        </div>
      </div>
      <div v-if="showNotEnough" class="basket-notification_content">
        <div class="basket-notification_icon">
          <div class="icon-basket"></div>
        </div>
        <div v-if="showNotEnoughTitle" class="basket-notification_title">
          {{ $t('К сожалению, товар') }} "{{ title }}" {{ $t('в запрашиваемом количестве недоступен') }}.
        </div>
        <div v-else class="basket-notification_title">
          {{ $t('К сожалению, некоторые товары из вашей корзины недоступны') }}.
        </div>
      </div>
    </div>
  </uc-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import NavigationRouteMixin from 'u-mixins/lib/NavigationRoute.mixin.js';

export default {
  name: 'BasketNotification',
  mixins: [NavigationRouteMixin],
  props: {
    isOrder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      showNotAvailable: false,
      showNotEnough: false,
      showNotAvailableTitle: false,
      showNotEnoughTitle: false,
      productCategorySlug: '',
      title: '',
      isNotification: true,
      listenToChanges: false
    };
  },
  computed: {
    ...mapState({
      notEnough: (state) => state.basket.notEnough,
      notAvailable: (state) => state.basket.notAvailable
    }),
    notificationTitle() {
      if (this.showNotAvailable) {
        return this.showNotAvailableTitle ? 'Товар распродан' : 'Товары распроданы';
      }
      return 'Количество ограничено';
    }
  },
  watch: {
    notEnough: {
      handler() {
        this.checkNotEnough();
      },
      deep: true
    },
    notAvailable: {
      handler() {
        this.checkNotAvailable();
      },
      deep: true
    }
  },
  created() {
    if (this.isNotification) {
      // notification mounted in default layout, it mounted immediate
      this.checkForStored();
      return;
    }

    this.checkNotEnough();
    this.checkNotAvailable();
  },
  methods: {
    ...mapActions({
      checkStoredUnavailable: 'basket/checkStoredUnavailable'
    }),
    async checkForStored() {
      await this.checkStoredUnavailable();
      this.listenToChanges = true;
    },
    hideModal() {
      this.show = false;
    },
    async checkNotAvailable() {
      if (!this.listenToChanges) {
        return;
      }
      if (!this.notEnough.length && !this.notAvailable.length) {
        this.show = false;
        return;
      }

      if (!this.notAvailable.length) {
        return;
      }

      this.showNotEnough = false;
      this.showNotAvailable = true;
      this.show = true;

      this.showNotAvailableTitle = this.notAvailable.length === 1;
      if (!this.showNotAvailableTitle) {
        return;
      }
      try {
        const { title, slug } = this.notAvailable[0];
        this.title = title;
        if (!this.passive) {
          const { data } = await this.$api.SearchService.getProduct(slug);
          this.productCategorySlug = data.category.slug
            ? this.buildParams(data.folderCategory.slug, data.category.slug)
            : this.buildParams(data.folderCategory.slug);
        }
      } catch (error) {
        console.error('Get Product By Slug Error', error);
        this.$sentry.captureException(error);
      }
    },
    checkNotEnough() {
      if (!this.listenToChanges) {
        return;
      }
      if (!this.notEnough.length && !this.notAvailable.length) {
        this.show = false;
        return;
      }

      if (!this.notEnough.length) {
        return;
      }

      this.showNotAvailable = false;
      this.showNotEnough = true;
      this.show = true;

      this.showNotEnoughTitle = this.notEnough.length === 1;
      if (!this.showNotEnoughTitle) {
        return;
      }

      this.title = this.notEnough[0].title;
    },
    toBasket() {
      this.$eventBus.$emit('basket_modal:display');
      this.hideModal();
    }
  }
};
</script>

<style lang="scss">
@import '~/assets/scss/notification.scss';
</style>
